import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { MatDialog } from "@angular/material";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { first } from "rxjs/operators";
import { ConfirmDialogComponent } from "../../../shared/components/confirm-dialog/confirm-dialog.component";
import { TableViewService } from "../../services/table-view.service";
import { TEXT_OF } from "../../utils/const.util";

@Component({
  selector: "app-table-view",
  templateUrl: "./table-view.component.html",
  styleUrls: ["./table-view.component.scss"],
})
export class TableViewComponent implements OnChanges, OnInit {
  @Input() columns = [];
  @Input() data = <any>[];
  @Input() api = "";
  @Input() editRoute = "";
  @Input() editDialog = null;
  @Input() deleteService = null;
  @Input() copyService = null;
  @Input() changeStatusService = null;
  @Input() name = "";
  @Input() additionalParams = {};

  public displayedColumns: string[];
  public totalRows = 0;
  public pageSize = 10;
  public pageIndex = 0;
  public sortColumn = "";
  public sortDirection = "";
  public search = "";
  public pageSizeOptions = [5, 10, 20, 50];

  constructor(
    public tableViewService: TableViewService,
    public dialog: MatDialog,
    private translate: TranslateService,
    private router: Router
  ) {}

  public refreshTable() {
    this.tableViewService
      .getData(
        this.api,
        this.pageIndex,
        this.pageSize,
        this.sortColumn,
        this.sortDirection,
        this.search,
        this.additionalParams
      )
      .subscribe((res) => {
        this.data = res.rows;
        this.totalRows = res.count;
      });
  }

  ngOnInit() {
    this.displayedColumns = this.columns.map((a) => a.id);
    this.displayedColumns.push(TEXT_OF.ICON_ACTION);
    this.refreshTable();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.name) this.refreshTable();
  }

  public handlePage(e: any) {
    this.pageIndex = e.pageIndex;
    this.pageSize = e.pageSize;
    this.refreshTable();
  }

  public handleSort(e: any) {
    this.sortColumn = e.active;
    this.sortDirection = e.direction;
    this.refreshTable();
  }

  public onSearch(searchValue: string): void {
    this.search = searchValue;
    this.refreshTable();
  }

  public openEdit(element: any) {
    this.router.navigate([this.editRoute, element.id]);
  }

  public openEditDialog(element: any) {
    this.editDialog(element.id);
  }

  public deleteAction(element: any) {
    var dialogText = this.name + ".confirmDeleteAction";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "300px",
      data: {
        title: "Delete",
        message: dialogText,
      },
      panelClass: "custom-modalbox",
      backdropClass: "cdk-overlay-transparent-backdrop",
      hasBackdrop: true,
    });

    // listen to response
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.delete(element);
      }
    });
  }

  public copyAction(element: any) {
    // let's call our modal window and prepare text fpr dialog
    var dialogText = this.name + ".confirmCopyAction";

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: "300px",
      data: {
        title: "Copy",
        message: dialogText,
      },
      panelClass: "custom-modalbox",
      backdropClass: "cdk-overlay-transparent-backdrop",
      hasBackdrop: true,
    });
    // listen to response
    dialogRef.afterClosed().subscribe((dialogResult) => {
      if (dialogResult) {
        this.copy(element);
      }
    });
  }

  public copy(element: any) {
    this.copyService(element.id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.refreshTable();
        },
        (error) => {
          this.refreshTable();
        }
      );
  }

  public delete(element: any) {
    this.deleteService(element.id)
      .pipe(first())
      .subscribe(
        (data) => {
          this.refreshTable();
        },
        (error) => {
          this.refreshTable();
        }
      );
  }

  public changeStatus(element: any, status) {
    this.changeStatusService(element.id, status)
      .pipe(first())
      .subscribe(
        (data) => {
          this.refreshTable();
        },
        (error) => {
          this.refreshTable();
        }
      );
  }
}
