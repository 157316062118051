export const environment = {
  production: false,
  docgenApiUrl: "api/dg",
  imageApiUrl: "api/dg",
  tenantManagementApiUrl: "api/tms/tenants",
  systemAdminApiUrl: "api/sas",

  idleTimeout: 7200000,
  enableLangSwich: true,
  preloadStrategy: true,
  froalaKey:
    "HQD1uA3B3D2E2D1D1F4mEZXQUVJe1EZb1IWIAUKLJZMBQuF2C1G1B1A10C1D7A1F6B4==",
};
