import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import * as moment_ from 'moment';
import {TranslateService} from '@ngx-translate/core';

const moment = moment_;
@Component({
  selector: 'app-language-picker',
  templateUrl: './language-picker.component.html',
  styleUrls: ['./language-picker.component.scss'],
})
export class LanguagePickerComponent implements OnInit, OnDestroy {
  public image: string = 'assets/img/svg/nl.svg';

  private subscription: Subscription;

  public menuActions = [
    {
      icon: 'flag-nl',
      image: 'assets/img/svg/nl.svg',
      tooltip: 'NL',
      language: 'nl'
    },
    {
      icon: 'flag-en',
      image: 'assets/img/svg/gb.svg',
      tooltip: 'EN',
      language: 'en'
    },
  ];

  constructor( private translateService: TranslateService
  ) {
    this.image = this.menuActions[1].image;
    this.translateService.setDefaultLang(this.menuActions[1].language);
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
  }

  setLanguage(img, language) {
    this.translateService.setDefaultLang(language);
    this.image = img;
  }
}
