import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatIconRegistry } from "@angular/material/icon";
import { MatSidenav } from "@angular/material/sidenav";
import { DomSanitizer } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { KeycloakService } from "keycloak-angular";
import { Subscription } from "rxjs";
import { AuthenticationService } from "./services";
import { ToastComponent } from "./shared/components/toast/toast.component";
import { PermissionService } from "./shared/services/permissions/permission.service";
import { RouterService } from "./shared/services/router.service";
import { Helper } from "./shared/utils/helper.util";
import { TenantConfigurationService } from "./services/tenant-configuration.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnDestroy, OnInit {
  title = "elise-docgen-frontend";
  isLoggedIn: boolean = true;
  accessForbiden: boolean = false;
  isCollapsedOrExpand = false;
  public pageTitle: string = "";
  private subscription: Subscription;

  constructor(
    private translate: TranslateService,
    private keycloakService: KeycloakService,
    public authService: AuthenticationService,
    public toast: ToastComponent,
    private domSanitizer: DomSanitizer,
    private matIconRegistry: MatIconRegistry,
    private permissionService: PermissionService,
    private routerService: RouterService,
    private configurationService: TenantConfigurationService
  ) {
    translate.setDefaultLang("en");
    this.matIconRegistry.addSvgIcon(
      "flag-en",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/img/svg/gb.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "flag-nl",
      this.domSanitizer.bypassSecurityTrustResourceUrl("assets/img/svg/nl.svg")
    );
  }

  ngOnInit() {
    this.configurationService.getTenantTimeZone();
    this.keycloakService.getToken().then((tokenData) => {
      const token = Helper.parseJwt(tokenData);
      this.permissionService.initRolePermissions(token).subscribe((data) => {});
    });

    this.subscription = this.routerService.getPageTitle().subscribe((event) => {
      this.pageTitle = event["title"];
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // Changing language on button press
  changeLanguage(language: string) {
    this.translate.setDefaultLang(language);
  }

  onToogle(drawer: MatSidenav) {
    drawer.toggle();
    this.isCollapsedOrExpand = !this.isCollapsedOrExpand;
  }
}
